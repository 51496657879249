import React, { useEffect, useState } from "react"

import BreakpointsContext, { Breakpoints } from "./BreakpointsContext"
import { useTheme } from "src/legacy/utils"

interface OwnProps {
  children: React.ReactChild | React.ReactChild[]
}

type BreakpointsProviderProps = OwnProps

const BreakpointsProvider: React.FC<BreakpointsProviderProps> = ({
  children,
}): React.ReactElement => {
  const [breakpoint, setBreakpoint] = useState<Breakpoints>("sm")
  const [windowWidth, setWindowWidth] = useState(0)
  const { breakpoints } = useTheme()

  const calculateBreakpoint = (screenWidth: number): Breakpoints => {
    const lg = parseInt(breakpoints.lg, 10) * 16
    const md = parseInt(breakpoints.md, 10) * 16
    const xl = parseInt(breakpoints.xl, 10) * 16

    if (screenWidth < md) return "sm"
    if (screenWidth < lg) return "md"
    if (screenWidth < xl) return "lg"
    return "xl"
  }

  useEffect(() => {
    const handleResize = (): void => {
      // Because of differences in browser window width calculations, this may
      // not line up exactly with CSS breakpoints across all browsers.
      const innerWidth = window.innerWidth
      const breakpoint = calculateBreakpoint(innerWidth)
      setBreakpoint(breakpoint)
      setWindowWidth(innerWidth)
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <BreakpointsContext.Provider value={{ breakpoint, windowWidth }}>
      {children}
    </BreakpointsContext.Provider>
  )
}

export default BreakpointsProvider
