import { buildThemeAliases } from "src/legacy/utils"
import { base } from "./base"

export const buttonHoverTextAliases = buildThemeAliases(base, {
  inline: "aqua",
  inlineNavy: "navy",
  primary: "navy",
  primaryAqua: "navy",
  primarySky: "offBlack",
  primarySkyDarkBackground: "offBlack",
  primaryWhite: "white",
  primaryWhiteSkyBorder: "sky",
  primaryYellow: "citrine",
  secondary: "navy",
  square: "navy",
})

export type ButtonHoverTextAliases = Record<
  keyof typeof buttonHoverTextAliases,
  string
>
