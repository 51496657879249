import { buildThemeAliases } from "src/legacy/utils"
import { colors } from "src/legacy/theme/colors"

const baseBorders = {
  none: "none",
  "1SolidAqua": `1px solid ${colors.aqua}`,
  "1SolidBlack": `1px solid ${colors.black}`,
  "1SolidBlue": `1px solid ${colors.blue}`,
  "1SolidGrayLight": `1px solid ${colors.grayLight}`,
  "1SolidNavy": `1px solid ${colors.navy}`,
  "1SolidOffBlack": `1px solid ${colors.offBlack}`,
  "1SolidSky": `1px solid ${colors.sky}`,
  "1Transparent": "1px solid transparent",
  "2SolidAqua": `2px solid ${colors.aqua}`,
  "2SolidBlack": `2px solid ${colors.black}`,
  "2SolidBlue": `2px solid ${colors.blue}`,
  "2SolidNavy": `2px solid ${colors.navy}`,
  "2SolidOffBlack": `2px solid ${colors.offBlack}`,
  "2SolidSky": `2px solid ${colors.sky}`,
  "2SolidWhite": `2px solid ${colors.white}`,
  "2Transparent": "2px solid transparent",
  "3SolidNavy": `3px solid ${colors.navy}`,
}

const baseAliases = buildThemeAliases(baseBorders, {
  aquaThin: "1SolidAqua",
  black: "2SolidBlack",
  blackMobile: "1SolidBlack",
  blogHero: "3SolidNavy",
  navBorder: "1SolidGrayLight",
  focus: "2SolidBlue",
  focusMobile: "1SolidBlue",
  navy: "2SolidNavy",
  navyMobile: "1SolidNavy",
  neutral: "2Transparent",
  neutralMobile: "1Transparent",
  offBlack: "2SolidOffBlack",
  offBlackThin: "1SolidOffBlack",
  sky: "2SolidSky",
  skyMobile: "1SolidSky",
})

export const base = {
  ...baseBorders,
  ...baseAliases,
}

export type Base = Record<keyof typeof base, string>
