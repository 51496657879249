import { buildThemeAliases } from "src/legacy/utils"
import { base } from "./base"

export const buttonTextAliases = buildThemeAliases(base, {
  inline: "navy",
  inlineNavy: "white",
  primary: "white",
  primaryAqua: "navy",
  primarySky: "offBlack",
  primarySkyDarkBackground: "offBlack",
  primaryWhite: "navy",
  primaryWhiteSkyBorder: "offBlack",
  primaryYellow: "offBlack",
  secondary: "navy",
  square: "white",
})

export type ButtonTextAliases = Record<keyof typeof buttonTextAliases, string>
