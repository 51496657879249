import { Maybe } from "types/Maybe"

const mapColumnSize = function (
  column_span: Maybe<string>
): Record<string, number> {
  const colSpan = column_span ? parseInt(column_span) : 10

  const gridColumnStart = (12 - colSpan) / 2 + 1
  const gridColumnEnd = 12 - (gridColumnStart - 2)

  return {
    gridColumnEnd,
    gridColumnStart,
  }
}

export default mapColumnSize
