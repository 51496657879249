import { Base, base } from "./base"
import { InputAliases, inputAliases } from "./inputAliases"

export interface Radii extends Base {
  input: InputAliases
}

export const radii: Radii = {
  ...base,
  input: inputAliases,
}
