export interface BreakpointsMap {
  sm: string
  md: string
  lg: string
  xl: string
}

const breakpointsMap: BreakpointsMap = {
  sm: "40rem",
  md: "52rem",
  lg: "64rem",
  xl: "80rem",
}

export type Breakpoints = Array<string> & BreakpointsMap

export const breakpoints: Breakpoints = Object.assign(
  Object.values(breakpointsMap),
  breakpointsMap
)
