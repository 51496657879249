import { buildThemeAliases } from "src/legacy/utils"

const baseZIndices = {
  "0": 0,
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
}

const baseAliases = buildThemeAliases(baseZIndices, {
  focus: "1",
})

export const base = {
  ...baseZIndices,
  ...baseAliases,
}

export type Base = Record<keyof typeof base, number>
