import { rem } from "polished"

export const base = {
  "8": rem("8px"),
  "9": rem("9px"),
  "10": rem("10px"),
  "11": rem("11px"),
  "12": rem("12px"),
  "13": rem("13px"),
  "14": rem("14px"),
  "15": rem("15px"),
  "16": rem("16px"),
  "17": rem("17px"),
  "18": rem("18px"),
  "20": rem("20px"),
  "22": rem("22px"),
  "24": rem("24px"),
  "25": rem("25px"),
  "26": rem("26px"),
  "28": rem("28px"),
  "32": rem("32px"),
  "36": rem("36px"),
  "42": rem("42px"),
  "44": rem("44px"),
  "50": rem("50px"),
  "54": rem("54px"),
  "62": rem("62px"),
  "70": rem("70px"),
  "72": rem("72px"),
}

export type Base = Record<keyof typeof base, string>
