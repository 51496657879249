import { buildThemeAliases } from "src/legacy/utils"
import { base } from "./base"

export const inputAliases = buildThemeAliases(base, {
  block: "33",
  inline: "33",
  mobile: "10",
  primary: "none",
})

export type InputAliases = Record<keyof typeof inputAliases, string>
