import { parsePhoneNumberFromString } from "libphonenumber-js"

function formatPhone(phoneAsString: string): string | null {
  const phoneNumber = parsePhoneNumberFromString(phoneAsString, "US")

  if (!phoneNumber) return null

  return phoneNumber.number as string
}

export default formatPhone
