import { Base, base } from "./base"
import { ButtonAliases, baseButtonAliases } from "./buttonAliases"
import { TextAliases, baseTextAliases } from "./textAliases"

export interface FontSizes extends Base {
  button: ButtonAliases
  text: TextAliases
}

export const fontSizes: FontSizes = {
  ...base,
  button: baseButtonAliases,
  text: baseTextAliases,
}
