import { base } from "./base"
import { buildThemeAliases } from "src/legacy/utils"

export const personaAliases = buildThemeAliases(base, {
  families: "suede",
  grandparents: "grapefruit",
  retired: "peach",
  singleParents: "denim",
  singles: "yellow",
  students: "lilac",
})

export type PersonaAliases = Record<keyof typeof personaAliases, string>
