import { buildThemeAliases } from "src/legacy/utils"

const baseRadii = {
  none: "none",
  "0": "0px",
  "3": "3px",
  "5": "5px",
  "10": "10px",
  "33": "33px",
}

const baseAliases = buildThemeAliases(baseRadii, {
  button: "33",
  buttonSquare: "5",
  focus: "3",
})

export const base = {
  ...baseRadii,
  ...baseAliases,
}

export type Base = Record<keyof typeof base, string>
