import { Maybe } from "types/Maybe"
import { PrismicImageType } from "types/__generated__/gatsby-graphql"

function getDisplayedImage(
  breakpoint: string,
  image: Maybe<PrismicImageType>,
  mobileImage: Maybe<PrismicImageType>
): Maybe<PrismicImageType> {
  if (mobileImage?.url && breakpoint === "sm") {
    if (!mobileImage?.alt && typeof mobileImage?.alt !== "string") {
      mobileImage.alt = ""
    }
    return mobileImage
  }

  if (image && !image?.alt && typeof image?.alt !== "string") {
    image.alt = ""
  }

  return image
}

export default getDisplayedImage
