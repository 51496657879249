import { buildThemeAliases } from "src/legacy/utils"

const baseFontWeights = {
  "400": 400,
  "600": 600,
  "800": 800,
}

const baseAliases = buildThemeAliases(baseFontWeights, {
  bold: "800",
  regular: "400",
  semibold: "600",
})

export const base = {
  ...baseFontWeights,
  ...baseAliases,
}

export type Base = Record<keyof typeof base, number>
