import { buildThemeAliases } from "src/legacy/utils"
import { base } from "./base"

export const baseButtonAliases = buildThemeAliases(base, {
  inline: "18",
  inlineNavy: "15",
  primary: "18",
  primaryAqua: "18",
  primarySky: "17",
  primarySkyDarkBackground: "17",
  primaryWhite: "18",
  primaryWhiteSkyBorder: "17",
  primaryYellow: "16",
  secondary: "16",
  square: "16",
})

export type ButtonAliases = Record<keyof typeof baseButtonAliases, string>
