import { buildThemeAliases } from "src/legacy/utils"

const baseColors = {
  aqua: "#8DD4BD",
  black: "#000000",
  blue: "#0078E3",
  citrine: "#E3D400",
  cream: "#F4E6DE",
  darkCyan: "#008291",
  denim: "#7BA0C4",
  grapefruit: "#FF5C35",
  gray: "#C4C4C4",
  grayLight: "#CCCCCC",
  lightCream: "#FEFAF9",
  lightWool: "#FCF9F7",
  lilac: "#DF9CDF",
  navy: "#0B1D2D",
  offBlack: "#1E1E1E",
  peach: "#FF9D6C",
  purple: "#660099",
  red: "#F00000",
  suede: "#DAB38D",
  sky: "#A7D4EC",
  teal: "#2E827C",
  turquoise: "#018290",
  transparent: "transparent",
  veryDarkCyan: "#003835",
  white: "#FFFFFF",
  wool: "#F2E8E2",
  yellow: "#FFD040",
}

const baseAliases = buildThemeAliases(baseColors, {
  border: "blue",
  placeholder: "gray",
  text: "navy",
})

export const base = {
  ...baseColors,
  ...baseAliases,
}

export type Base = Record<keyof typeof base, string>
