import { Base, base } from "./base"
import {
  ButtonBackgroundAliases,
  buttonBackgroundAliases,
} from "./buttonBackgroundAliases"
import {
  ButtonHoverBackgroundAliases,
  buttonHoverBackgroundAliases,
} from "./buttonHoverBackgroundAliases"
import {
  ButtonHoverTextAliases,
  buttonHoverTextAliases,
} from "./buttonHoverTextAliases"
import { ButtonTextAliases, buttonTextAliases } from "./buttonTextAliases"
import { PersonaAliases, personaAliases } from "./personaAliases"
import { TextAliases, textAliases } from "./textAliases"
import { TextHoverAliases, textHoverAliases } from "./textHoverAliases"

export interface Colors extends Base {
  buttonBackground: ButtonBackgroundAliases
  buttonHoverBackground: ButtonHoverBackgroundAliases
  buttonHoverText: ButtonHoverTextAliases
  buttonText: ButtonTextAliases
  persona: PersonaAliases
  textColor: TextAliases
  textHoverColor: TextHoverAliases
}

export const colors: Colors = {
  ...base,
  buttonBackground: buttonBackgroundAliases,
  buttonHoverBackground: buttonHoverBackgroundAliases,
  buttonHoverText: buttonHoverTextAliases,
  buttonText: buttonTextAliases,
  persona: personaAliases,
  textColor: textAliases,
  textHoverColor: textHoverAliases,
}
