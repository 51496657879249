import { createContext } from "react"

import { BreakpointsMap } from "src/legacy/theme/breakpoints/breakpoints"

export type Breakpoints = keyof BreakpointsMap

export interface BreakpointsContext {
  breakpoint: Breakpoints
  windowWidth: number
}

export default createContext<BreakpointsContext>({
  breakpoint: "sm",
  windowWidth: 0,
})
