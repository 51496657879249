type Locality = {
  description?: string
  facilityName: string
  facilityUrl: string
  name: string
  state: string
  state_abbreviated?: string
  zip_code: string
}

export type LocalityTypes = Locality

function getLocalities(items: [], gasGuaranteedData): [] {
  if (!items) {
    return []
  }

  return Object.entries(items).map(item => {
    const locationData = item[1];
    const matchingGasGuarantee =
      gasGuaranteedData?.length &&
      gasGuaranteedData.filter(zipItem => {
        return locationData.zipCodes?.includes(zipItem.zip_code)
      })
    if (matchingGasGuarantee?.length) {
      matchingGasGuarantee.forEach(matchingItem => {
        const { zip_code, gas_guarantee_amount } = matchingItem
        item.gasGuaranteeAmount = {
          ...item.gasGuaranteeAmount,
          [zip_code]: gas_guarantee_amount
        }
      })
    }

    return {
      comingSoon: locationData.comingSoon,
      facilityName:  locationData.location,
      facilityUrl: locationData.url,
      zip_code: locationData.zipCodes,
      ...(item.gasGuaranteeAmount && {
        gasGuaranteeAmount: item.gasGuaranteeAmount,
      })
    }
  })
}

export default getLocalities
