import { base } from "./base"
import { buildThemeAliases } from "src/legacy/utils"

export const baseTextAliases = buildThemeAliases(base, {
  body: "regular",
  button: "semibold",
  buttonLong: "semibold",
  buttonSecondary: "regular",
  buttonSecondaryRedesigned: "regular",
  buttonSquare: "regular",
  caption: "regular",
  captionCaps: "regular",
  captionSmall: "regular",
  copyright: "regular",
  displayBody: "regular",
  error: "semibold",
  footerLink: "regular",
  footerHeader: "bold",
  header: "bold",
  headerExtraLarge: "bold",
  headerLarge: "bold",
  headerSmall: "bold",
  legal: "regular",
  navLink: "regular",
  subheader: "bold",
  subheaderSmall: "semibold",
})

export type TextAliases = Record<keyof typeof baseTextAliases, number>
