import { buildThemeAliases } from "src/legacy/utils"
import { base } from "./base"

export const buttonAliases = buildThemeAliases(base, {
  inline: "semibold",
  inlineNavy: "regular",
  primary: "semibold",
  primaryAqua: "semibold",
  primarySky: "regular",
  primarySkyDarkBackground: "regular",
  primaryWhite: "semibold",
  primaryWhiteSkyBorder: "regular",
  primaryYellow: "bold",
  secondary: "regular",
  square: "regular",
})

export type ButtonAliases = Record<keyof typeof buttonAliases, string>
