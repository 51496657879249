import { buildThemeAliases } from "src/legacy/utils"
import { base } from "./base"

export const textAliases = buildThemeAliases(base, {
  body: "navy",
  button: "white",
  buttonLong: "white",
  buttonSecondary: "navy",
  buttonSecondaryRedesigned: "navy",
  buttonSquare: "navy",
  caption: "navy",
  captionCaps: "navy",
  captionSmall: "navy",
  copyright: "aqua",
  displayBody: "navy",
  error: "red",
  footerHeader: "white",
  footerLink: "white",
  header: "navy",
  headerExtraLarge: "navy",
  headerLarge: "navy",
  headerSmall: "navy",
  legal: "navy",
  link: "navy",
  linkVisited: "purple",
  navLink: "navy",
  subheader: "navy",
  subheaderSmall: "navy",
})

export type TextAliases = Record<keyof typeof textAliases, string>
