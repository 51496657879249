import { buildThemeAliases } from "src/legacy/utils"

const baseLetterSpacings = {
  "0": "0",
  "0.09": "0.09rem",
}

const baseAliases = buildThemeAliases(baseLetterSpacings, {
  normal: "0",
  wide: "0.09",
})

export const base = {
  ...baseLetterSpacings,
  ...baseAliases,
}

export type Base = Record<keyof typeof base, string>
