import React from "react"
import { ThemeProvider } from "styled-components"

import BreakpointsProvider from "legacy/components/shared/BreakpointsProvider"
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews"
import theme from "theme"

import './src/styles/global.css'

interface OwnProps {
  element: React.ReactElement
}

type GatsbyBrowserProps = OwnProps

export const wrapRootElement: React.FC<GatsbyBrowserProps> = ({
  element,
}): React.ReactElement => (
  <ThemeProvider theme={theme}>
    <BreakpointsProvider>
      <PrismicPreviewProvider>{element}</PrismicPreviewProvider>
    </BreakpointsProvider>
  </ThemeProvider>
)
