import { Base, base } from "./base"
import { ButtonAliases, buttonAliases } from "./buttonAliases"
import { InputAliases, inputAliases } from "./inputAliases"

export interface Space extends Base {
  button: ButtonAliases
  input: InputAliases
}

export const space: Space = {
  ...base,
  button: buttonAliases,
  input: inputAliases,
}
