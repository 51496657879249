import { base } from "./base"
import { buildThemeAliases } from "src/legacy/utils"

export const baseTextAliases = buildThemeAliases(base, {
  body: "martel",
  button: "martel",
  buttonLong: "martel",
  buttonSecondary: "martel",
  buttonSecondaryRedesigned: "martel",
  buttonSquare: "martel",
  caption: "martel",
  captionCaps: "martel",
  captionSmall: "martel",
  copyright: "martel",
  displayBody: "martel",
  error: "martel",
  footerLink: "martel",
  footerHeader: "poppins",
  header: "poppins",
  headerExtraLarge: "poppins",
  headerLarge: "poppins",
  headerSmall: "poppins",
  legal: "martel",
  navLink: "martel",
  subheader: "poppins",
  subheaderSmall: "poppins",
})

export type TextAliases = Record<keyof typeof baseTextAliases, string>
