export const base = {
  contentWidth: "1140px",
  footerHeight: "350px",
  footerHeightMobile: "425px",
  footerWidth: "1220px",
  iconDesktop: "33px",
  iconMobile: "22px",
  inputMaxWidth: "845px",
  inputHeight: "65px",
  maxWidth: "1440px",
}

export type Base = Record<keyof typeof base, string>
