import { buildThemeAliases } from "src/legacy/utils"
import { base } from "./base"

export const textHoverAliases = buildThemeAliases(base, {
  footerLink: "aqua",
  link: "teal",
  navLink: "teal",
})

export type TextHoverAliases = Record<keyof typeof textHoverAliases, string>
