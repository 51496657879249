import { useEffect, useRef, MutableRefObject, Ref, RefObject } from "react"

function useCombinedRef<T>(...refs: Ref<T>[]): RefObject<T> {
  const targetRef = useRef<T>(null)

  useEffect(() => {
    refs.forEach(ref => {
      if (!ref) return
      if (!targetRef.current) return

      if (typeof ref === "function") {
        ref(targetRef.current)
      } else {
        ;(ref as MutableRefObject<T>).current = targetRef.current
      }
    })
  }, [refs])

  return targetRef
}

export default useCombinedRef
