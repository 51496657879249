import { Base, base } from "./base"
import { ButtonAliases, buttonAliases } from "./buttonAliases"
import { TextAliases, textAliases } from "./textAliases"

export interface LetterSpacings extends Base {
  button: ButtonAliases
  text: TextAliases
}

export const letterSpacings: LetterSpacings = {
  ...base,
  button: buttonAliases,
  text: textAliases,
}
