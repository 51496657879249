import { Base, base } from "./base"
import { TextAliases, baseTextAliases } from "./textAliases"

export interface TextTransforms extends Base {
  text: TextAliases
}

export const textTransforms: TextTransforms = {
  ...base,
  text: baseTextAliases,
}
