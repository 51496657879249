import { ButtonVariant } from "legacy/components/shared/Button"
import { Maybe } from "types/Maybe"

const mapButtonVariant = function (passedColor: Maybe<string>): ButtonVariant {
  if (!passedColor) return "primary"

  type ButtonVariantMapType = Record<string, ButtonVariant>

  const buttonVariantMap: ButtonVariantMapType = {
    aqua: "primaryAqua",
    navy: "primary",
    sky: "primarySky",
    transparent: "secondary",
    white: "primaryWhite",
  }

  return buttonVariantMap[passedColor]
}

export default mapButtonVariant
