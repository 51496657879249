type UTMParams = {
  utm_source?: string
  utm_campaign?: string
  utm_medium?: string
  utm_content?: string
  utm_text?: string
}

function getUtmQueryParams(utmParams: UTMParams): string {
  if (!utmParams) {
    return ""
  }
  const queryParams: string[] = []

  Object.entries(utmParams).reduce((queryParams, [key, val]) => {
    if (val) queryParams.push(`${key}=${val}`)
    return queryParams
  }, queryParams)

  if (queryParams.length > 0) {
    return `?${queryParams.join("&")}`
  } else {
    return ""
  }
}

export default getUtmQueryParams
