import { buildThemeAliases } from "src/legacy/utils"
import { base } from "./base"

export const buttonHoverBackgroundAliases = buildThemeAliases(base, {
  inline: "transparent",
  inlineNavy: "transparent",
  primary: "transparent",
  primaryAqua: "transparent",
  primarySky: "transparent",
  primarySkyDarkBackground: "white",
  primaryWhite: "veryDarkCyan",
  primaryWhiteSkyBorder: "transparent",
  primaryYellow: "black",
  secondary: "transparent",
  square: "transparent",
})

export type ButtonHoverBackgroundAliases = Record<
  keyof typeof buttonHoverBackgroundAliases,
  string
>
