import { buildThemeAliases } from "src/legacy/utils"
import { base } from "./base"

export const buttonAliases = buildThemeAliases(base, {
  inline: "2SolidAqua",
  inlineNavy: "2SolidNavy",
  primary: "2SolidNavy",
  primaryAqua: "2SolidAqua",
  primarySky: "2SolidSky",
  primarySkyDarkBackground: "2SolidSky",
  primaryWhite: "2SolidWhite",
  primaryWhiteSkyBorder: "2SolidSky",
  primaryYellow: "2SolidYellow",
  secondary: "none",
  square: "2SolidNavy",
})

export type ButtonAliases = Record<keyof typeof buttonAliases, string>
