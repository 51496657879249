import { buildThemeAliases } from "src/legacy/utils"
import { base } from "./base"

export const buttonHoverAliases = buildThemeAliases(base, {
  inline: "2SolidWhite",
  inlineNavy: "2SolidNavy",
  primary: "2SolidNavy",
  primaryAqua: "2SolidAqua",
  primarySky: "2SolidSky",
  primarySkyDarkBackground: "2SolidSky",
  primaryWhite: "2SolidWhite",
  primaryWhiteSkyBorder: "2SolidSky",
  primaryYellow: "2SolidYellow",
  secondary: "none",
  square: "2SolidNavy",
})

export type ButtonHoverAliases = Record<keyof typeof buttonHoverAliases, string>
