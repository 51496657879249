import { base } from "./base"
import { buildThemeAliases } from "src/legacy/utils"

export const textAliases = buildThemeAliases(base, {
  button: "wide",
  buttonSecondary: "wide",
  buttonRedesigned: "wide",
  caption: "wide",
  captionCaps: "wide",
  captionSmall: "wide",
  footerLink: "wide",
})

export type TextAliases = Record<keyof typeof textAliases, string>
