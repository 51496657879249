import camelCase from "lodash/camelCase"
import { Maybe } from "types/Maybe"

const mapFontSize = function (
  textElement: string,
  fontSize: Maybe<string>
): string[] {
  type FontSizeMapType = Record<string, Record<string, string[]>>
  const fontSizeMap: FontSizeMapType = {
    header: {
      subheader: ["18", "text.subheader"],
      regular: ["26", "text.header"],
      small: ["18", "text.headerSmall"],
      large: ["26", "text.headerLarge"],
      extraLarge: ["28", "text.headerExtraLarge"],
    },
  }

  const size = fontSize || "regular"
  const formattedTextElement = camelCase(textElement)
  const formattedFontSize = camelCase(size)
  const fontSizeObj = fontSizeMap[formattedTextElement]

  return fontSizeObj[formattedFontSize]
}

export default mapFontSize
