import { base } from "./base"
import { buildThemeAliases } from "src/legacy/utils"

export const baseTextAliases = buildThemeAliases(base, {
  button: "uppercase",
  buttonSecondary: "uppercase",
  buttonSecondaryRedesigned: "uppercase",
  buttonSquare: "uppercase",
  captionCaps: "uppercase",
  footerLink: "uppercase",
  navLink: "uppercase",
})

export type TextAliases = Record<keyof typeof baseTextAliases, string>
