import { buildThemeAliases } from "src/legacy/utils"
import { base } from "./base"

export const buttonAliases = buildThemeAliases(base, {
  gutterX: "30",
  gutterY: "15",
  gutterXSmall: "40",
  gutterYSmall: "10",
})

export type ButtonAliases = Record<keyof typeof buttonAliases, string>
