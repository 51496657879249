import { buildThemeAliases } from "src/legacy/utils"
import { base } from "./base"

export const baseTextAliases = buildThemeAliases(base, {
  body: "18",
  button: "16",
  buttonLong: "16",
  buttonSecondary: "17",
  buttonSecondaryRedesigned: "16",
  buttonSquare: "16",
  caption: "15",
  captionCaps: "15",
  captionSmall: "10",
  copyright: "14",
  displayBody: "36",
  error: "18",
  footerHeader: "18",
  footerLink: "15",
  footnote: "11",
  header: "42",
  headerExtraLarge: "70",
  headerSmall: "32",
  headerLarge: "54",
  legal: "12",
  navLink: "13",
  subheader: "24",
  subheaderSmall: "18",
})

export type TextAliases = Record<keyof typeof baseTextAliases, string>
