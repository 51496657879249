import { buildThemeAliases } from "src/legacy/utils"
import { rem } from "polished"

const baseSpaces = {
  "0": rem("0px"),
  "4": rem("4px"),
  "5": rem("5px"),
  "10": rem("10px"),
  "12": rem("12px"),
  "15": rem("15px"),
  "20": rem("20px"),
  "22": rem("22px"),
  "25": rem("25px"),
  "30": rem("30px"),
  "32": rem("32px"),
  "35": rem("35px"),
  "40": rem("40px"),
  "45": rem("45px"),
  "50": rem("50px"),
  "52": rem("52px"),
  "55": rem("55px"),
  "60": rem("60px"),
  "65": rem("65px"),
  "70": rem("70px"),
  "75": rem("75px"),
  "80": rem("80px"),
  "85": rem("85px"),
  "90": rem("90px"),
  "95": rem("95px"),
  "100": rem("100px"),
  "105": rem("105px"),
  "108": rem("108px"),
  "110": rem("110px"),
  "115": rem("115px"),
  "120": rem("120px"),
  "125": rem("125px"),
  "130": rem("130px"),
  "135": rem("135px"),
  "140": rem("140px"),
  "145": rem("145px"),
  "150": rem("150px"),
  "200": rem("200px"),
  "240": rem("240px"),
}

const baseAliases = buildThemeAliases(baseSpaces, {
  xs: "4",
  sm: "20",
  md: "32",
  lg: "52",
  xlg: "80",
  xxlg: "108",
  lineHeightOffset: "4",
})

export const base = {
  ...baseSpaces,
  ...baseAliases,
}

export type Base = Record<keyof typeof base, string>
