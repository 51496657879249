import { buildThemeAliases } from "src/legacy/utils"
import { colors } from "src/legacy/theme/colors"

const baseShadows = {
  none: "none",
  "1SolidWhite": `0 0 0 1px ${colors.white}`,
  "1Transparent": "0 0 0 1px transparent",
}

const baseAliases = buildThemeAliases(baseShadows, {
  focus: "1SolidWhite",
  neutral: "1Transparent",
})

export const base = {
  ...baseShadows,
  ...baseAliases,
}

export type Base = Record<keyof typeof base, string>
