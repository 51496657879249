const DEFAULT_SITE_URL = "https://www.joinparachute.com/"

function defaultCanonicalLink(
  relativePageUrl: string,
  siteUrl = DEFAULT_SITE_URL
): string {
  if (!relativePageUrl) return ""

  const siteUrlValue = siteUrl
  const siteUrlWithoutTrailingSlash = siteUrlValue.replace(/\/+$/, "")
  return `${siteUrlWithoutTrailingSlash}${relativePageUrl}`
}

export default defaultCanonicalLink
