import { base } from "./base"
import { buildThemeAliases } from "src/legacy/utils"

export const buttonAliases = buildThemeAliases(base, {
  inline: "wide",
  inlineNavy: "wide",
  primary: "wide",
  primaryAqua: "wide",
  primarySky: "wide",
  primarySkyDarkBackground: "wide",
  primaryWhite: "wide",
  primaryWhiteSkyBorder: "wide",
  primaryYellow: "wide",
  secondary: "wide",
  square: "normal",
})

export type ButtonAliases = Record<keyof typeof buttonAliases, string>
