import { Base, base } from "./base"
import { ButtonAliases, buttonAliases } from "./buttonAliases"
import { ButtonHoverAliases, buttonHoverAliases } from "./buttonHoverAliases"
import { InputAliases, inputAliases } from "./inputAliases"
import { InputFocusAliases, inputFocusAliases } from "./inputFocusAliases"

export interface Borders extends Base {
  button: ButtonAliases
  buttonHover: ButtonHoverAliases
  input: InputAliases
  inputFocus: InputFocusAliases
}

export const borders: Borders = {
  ...base,
  button: buttonAliases,
  buttonHover: buttonHoverAliases,
  input: inputAliases,
  inputFocus: inputFocusAliases,
}
