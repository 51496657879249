import { buildThemeAliases } from "src/legacy/utils"
import { base } from "./base"

export const buttonBackgroundAliases = buildThemeAliases(base, {
  inline: "aqua",
  inlineNavy: "navy",
  primary: "navy",
  primaryAqua: "aqua",
  primarySky: "sky",
  primarySkyDarkBackground: "sky",
  primaryWhite: "white",
  primaryWhiteSkyBorder: "white",
  primaryYellow: "citrine",
  secondary: "transparent",
  square: "navy",
})

export type ButtonBackgroundAliases = Record<
  keyof typeof buttonBackgroundAliases,
  string
>
