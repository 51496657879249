import { buildThemeAliases } from "src/legacy/utils"

const baseGridTemplateColumns = {
  "1": "repeat(12, 1fr)",
  "1|10|1": "1fr 10fr 1fr",
  "1.5|9|1.5": "1.5fr 9fr 1.5fr",
  "2|8|2": "2fr 8fr 2fr",
  "3|1|8": "3fr 1fr  8fr",
  "4|4|4": "repeat(3, 4fr)",
  "6|6": "repeat(2, 6fr)",
  "7|5": "7fr 5fr",
}

const baseAliases = buildThemeAliases(baseGridTemplateColumns, {
  "1col": "1",
  "1col66": "2|8|2",
  "1col75": "1.5|9|1.5",
  "1col80": "1|10|1",
  "2col": "6|6",
  "2colLeft": "7|5",
  "2colRight": "3|1|8",
  "3col": "4|4|4",
})

export const base = {
  ...baseGridTemplateColumns,
  ...baseAliases,
}

export type Base = Record<keyof typeof base, string>
