import { Base, base } from "./base"
import { TextAliases, baseTextAliases } from "./textAliases"

export interface Fonts extends Base {
  text: TextAliases
}

export const fonts: Fonts = {
  ...base,
  text: baseTextAliases,
}
