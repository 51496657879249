import { Borders, borders } from "./borders"
import { Colors, colors } from "./colors"
import { Fonts, fonts } from "./fonts"
import { FontSizes, fontSizes } from "./fontSizes"
import { FontWeights, fontWeights } from "./fontWeights"
import { GridTemplateColumns, gridTemplateColumns } from "./gridTemplateColumns"
import { LetterSpacings, letterSpacings } from "./letterSpacing"
import { ObjectFits, objectFits } from "./objectFits"
import { Radii, radii } from "./radii"
import { Shadows, shadows } from "./shadows"
import { Sizes, sizes } from "./sizes"
import { Space, space } from "./space"
import { TextTransforms, textTransforms } from "./textTransforms"
import { Transforms, transforms } from "./transforms"
import { ZIndices, zIndices } from "./zIndices"
import { breakpoints, Breakpoints } from "./breakpoints"

export interface ThemeProps {
  borders: Borders
  breakpoints: Breakpoints
  colors: Colors
  fonts: Fonts
  fontSizes: FontSizes
  fontWeights: FontWeights
  gridTemplateColumns: GridTemplateColumns
  letterSpacings: LetterSpacings
  objectFits: ObjectFits
  radii: Radii
  shadows: Shadows
  sizes: Sizes
  space: Space
  textTransforms: TextTransforms
  transforms: Transforms
  zIndices: ZIndices
}

const theme: ThemeProps = {
  borders,
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  gridTemplateColumns,
  letterSpacings,
  objectFits,
  radii,
  shadows,
  sizes,
  space,
  textTransforms,
  transforms,
  zIndices,
}

export default theme
